<script setup lang="ts">
import type { Placement } from '@floating-ui/vue'
import type { MaybeElement } from '@vueuse/core'
import { provide } from 'vue'
import { ref } from '#imports'
import MenuContent from '../private/MenuContent.vue'
import TransitionFade from './transition/Fade.vue'

const isOpen = defineModel<boolean>('open')

defineProps<{
  disabled?: boolean
  offset?: number
  placement?: Placement
  menuClasses?: string
  menuStyles?: Record<string, string | number>
}>()

const anchorRef = ref<MaybeElement>(null)

provide('anchor', anchorRef)

const toggle = (value = !isOpen.value) => {
  isOpen.value = value
}

const onClickOutside = (e: MouseEvent) => {
  if (anchorRef.value?.contains(e.target as Node)) return
  toggle(false)
}

defineSlots<{
  default(props: {
    isOpen: boolean
    toggle: typeof toggle
    maxDimensions: { maxWidth: string; maxHeight: string }
  }): void
  activator(props: { isOpen: boolean; toggle: typeof toggle }): void
}>()

// https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Roles/menu_role
</script>

<template>
  <div ref="anchorRef" @keyup.esc.stop.prevent="toggle(false)">
    <slot v-bind="{ isOpen, toggle }" name="activator" />
    <ClientOnly>
      <Teleport to="body">
        <TransitionFade
          enter-active-class="duration-300"
          leave-active-class="duration-250"
        >
          <MenuContent
            v-if="!disabled && isOpen"
            v-slot="{ maxDimensions }"
            :class="menuClasses"
            :style="menuStyles"
            :offset="offset"
            :placement="placement"
            @click-outside="onClickOutside"
            @keydown.esc="toggle(false)"
          >
            <slot v-bind="{ isOpen, toggle, maxDimensions }" />
          </MenuContent>
        </TransitionFade>
      </Teleport>
    </ClientOnly>
  </div>
</template>
